import { ORGAN_ORG, USER, DICTIONARY } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询组织树
export const getOrgTree = params => request(`${ORGAN_ORG}/getOrgTree`, METHOD.GET, params, { loading: true })

// 查询组织类型列表
export const getOrgTypeList = params => request(`${ORGAN_ORG}/getOrgTypeList`, METHOD.GET, params)

// 新增组织
export const createOrg = params => request(`${ORGAN_ORG}/createOrg`, METHOD.POST, params, { loading: true })

// 修改组织
export const updateOrg = params => request(`${ORGAN_ORG}/updateOrg`, METHOD.POST, params)

// 移动组织
export const moveOrg = params => request(`${ORGAN_ORG}/moveOrg`, METHOD.POST, params)

// 删除组织
export const deleteOrg = params => request(`${ORGAN_ORG}/deleteOrg`, METHOD.POST, params, { loading: true })

// 合并
export const mergeOrg = params => request(`${ORGAN_ORG}/mergeOrg`, METHOD.POST, params)// 合并

// 归档
export const archive = params => request(`${DICTIONARY}/archive`, METHOD.POST, params)

// 获取组织详情
export const getOrgDetails = params => request(`${ORGAN_ORG}/getOrgDetails`, METHOD.GET, params)

// 添加虚拟员工
export const addPerson = params => request(`${ORGAN_ORG}/addPerson`, METHOD.POST, params)

// 获取虚拟组织开关状态
export const getOnOffInventOrg = params => request(`${ORGAN_ORG}/getOnOffInventOrg`, METHOD.GET, params)

// 编辑开关虚拟组织
// export const editOnOffInventOrg = params => request(`${ORGAN_ORG}/editOnOffInventOrg?onOff=${params}`, METHOD.POST)
export const editOnOffInventOrg = params => request(`${ORGAN_ORG}/editOnOffInventOrg`, METHOD.POST, params)

// 修改组织负责人
export const updateLeader = params => request(`${ORGAN_ORG}/updateLeader`, METHOD.POST, params)

// 已离职导出
export async function exportOrgTree(params) {
  return request(`${USER + '/org/exportOrgTree'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 查询组织类别列表
export const getOrgTypeAllList = params => request(`${ORGAN_ORG}/getOrgTypeAllList`, METHOD.GET, params)

// 新增或修改组织类别
export const createOrUpdateOrgType = params => request(`${ORGAN_ORG}/createOrUpdateOrgType`, METHOD.POST, params)

// 删除组织类别
export const deleteOrgType = params => request(`${ORGAN_ORG}/deleteOrgType`, METHOD.POST, params)

// 归档记录时间下拉框数据
export const getTimeSelection = params => request(`${USER}/archive/getTimeSelection`, METHOD.GET, params)

// 查询归档组织树
export const getOrgTreeArchive = params => request(`${USER}/archive/getOrgTree`, METHOD.GET, params)

// 查询归档的岗位分类
export const getPostTypeList = params => request(`${USER}/archive/getPostTypeList`, METHOD.GET, params)

// 岗位分类统计数据
export const getPostTypeStatistics = params => request(`${USER}/archive/getPostTypeStatistics`, METHOD.GET, params)

// 查询归档的岗位列表
export const getPostList = params => request(`${USER}/archive/getPostList`, METHOD.GET, params)

// 查询归档的职级分类
export const getRankTypeList = params => request(`${USER}/archive/getRankTypeList`, METHOD.GET, params)

// 职级分类统计数据
export const getRankStatistics = params => request(`${USER}/archive/getRankStatistics`, METHOD.GET, params)

// 查询归档的职级列表
export const getRankList = params => request(`${USER}/archive/getRankList`, METHOD.GET, params)

// 查询归档的职级列表
export const getOrgMaxLayers = params => request(`${ORGAN_ORG}/getOrgMaxLayers`, METHOD.GET, params)

// 批量导入组织--初步校验接口
export const batchImportOrg = params => request(`${ORGAN_ORG}/batchImportOrg`, METHOD.POST, params)

// 批量导入数据--实际导入接口
export const checkBatchImportOrg = params => request(`${ORGAN_ORG}/checkBatchImportOrg`, METHOD.POST, params)

// 导出归档组织树
export async function fileExportOrgTree(params) {
  return request(`${USER + '/archive/exportOrgTree'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 导出岗位归档信息
export async function fileExportPostList(params) {
  return request(`${USER + '/archive/exportPostList'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 导出职级归档信息
export async function fileExportRankList(params) {
  return request(`${USER + '/archive/exportRankList'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 【迭代】删除虚拟组织员工
export const deleteInvertStaff = params => request(`${USER}/org/deleteInvertStaff`, METHOD.POST, params)

// 根据组织名称模糊查询
export const getOrgLikeOrgName = params => request(`${USER}/org/getOrgLikeOrgName`, METHOD.GET, params)
