var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        closable: true,
        visible: _vm.visible,
        title: (_vm.mode == "add" ? "新建" : "编辑") + "岗位",
        size: "normal",
        "mask-closable": false,
        "ok-text": "保存",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "岗位名称", prop: "postName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入1-30个字符" },
                model: {
                  value: _vm.form.postName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "postName", $$v)
                  },
                  expression: "form.postName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "适用组织", prop: "orgList" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.treeData,
                  placeholder: "请选择适用组织",
                  "allow-clear": "",
                  multiple: "",
                  "replace-fields": {
                    children: "children",
                    title: "orgName",
                    key: "orgId",
                    value: "orgId",
                  },
                  "tree-default-expand-all": "",
                  "search-value": _vm.searchvalue,
                  "tree-node-filter-prop": "title",
                },
                on: { search: _vm.handleSearch },
                model: {
                  value: _vm.form.orgList,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "orgList", $$v)
                  },
                  expression: "form.orgList",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "对应职类" } },
            [
              _c("a-select", {
                attrs: {
                  placeholder: "请选择",
                  options: _vm.postGroup,
                  "allow-clear": "",
                },
                on: { change: _vm.jobTypeIdChange },
                model: {
                  value: _vm.form.jobTypeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "jobTypeId", $$v)
                  },
                  expression: "form.jobTypeId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "对应职级", prop: "postUpper" } },
            [
              _c(
                "div",
                { staticClass: "choice_rank" },
                [
                  _c("span", { staticClass: "choice_rank_top" }, [
                    _vm._v("下限"),
                  ]),
                  _c("a-select", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      disabled: _vm.category,
                      options: _vm.rankList,
                      placeholder: "请选择",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.jobGradeFloor,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "jobGradeFloor", $$v)
                      },
                      expression: "form.jobGradeFloor",
                    },
                  }),
                  _c("span", { staticClass: "choice_rank_bottom" }, [
                    _vm._v("上限"),
                  ]),
                  _c("a-select", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      disabled: _vm.category,
                      options: _vm.rankList,
                      placeholder: "请选择",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.jobGradeUpper,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "jobGradeUpper", $$v)
                      },
                      expression: "form.jobGradeUpper",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "岗位说明", prop: "detail" } },
            [
              _c("a-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入0-200个字符",
                  "auto-size": { minRows: 3, maxRows: 7 },
                },
                model: {
                  value: _vm.form.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "detail", $$v)
                  },
                  expression: "form.detail",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }